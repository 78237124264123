<template>
  <g>
    <!-- inox BEGIN -->
    <linearGradient id="inox-gradient"
                    x1="0%"
                    y1="0%"
                    x2="130%"
                    y2="130%"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0%" stop-color="#bcb9b6"/>
      <stop offset="33%" stop-color="#969593"/>
      <stop offset="50%" stop-color="#bcb8b4"/>
      <stop offset="66%" stop-color="#e1e0e0"/>
      <stop offset="100%" stop-color="#e3e3e2"/>
    </linearGradient>

    <rect id="inox"
          v-if="showInox"
          data-name="inox"
          class="inox"
          :x="lineX"
          :y="inoxY1"
          :width="inoxWidth"
          :height="inoxHeight"/>

    <rect id="inox-2"
          v-if="showInox"
          data-name="inox"
          class="inox"
          :x="lineX"
          :y="inoxY2"
          :width="inoxWidth"
          :height="inoxHeight"/>

    <rect id="inox-3"
          v-if="showInox"
          data-name="inox"
          class="inox"
          :x="lineX"
          :y="inoxY3"
          :width="inoxWidth"
          :height="inoxHeight"/>

    <line class="line"
          v-if="showImpost"
          :x1="lineX"
          :y1="lineY1"
          :x2="lineX"
          :y2="lineY2"/>
    <!-- inox END -->

    <!-- glass BEGIN -->
    <rect filter="url(#inset-shadow)"
          id="glass"
          class="glass"
          :x="glassX"
          :y="glassY"
          :width="glassWidth"
          :height="inoxLineHeight"/>
    <!-- glass END -->
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      glassWidth: 25.82,
      doorLeafOffset: 22,
      lineOffset: 59.59,
      inoxHeight: 2.13,
      leaf2Left: 7,
    }
  },
  computed: {
    showImpost() {
      return this.showBg;
    },
    glassX() {
      return this.doorLeftWidth + this.doorLeafOffset
    },
    glassY() {
      return this.doorTopHeight + this.doorLeafOffset
    },
    inoxLineHeight() {
      return this.doorHeight - 2 * this.doorLeafOffset
    },
    lineX() {
      return this.showBg ?
        this.doorLeftWidth + this.lineOffset :
        this.doorLeftWidth + this.glassWidth + this.glassX;
    },
    lineY2() {
      return this.showBg ?
        this.doorTopHeight + this.doorLeafOffset :
        this.doorTopHeight + this.leaf2Left
    },
    lineY1() {
      return this.showBg ?
        this.doorTopHeight + this.doorHeight - this.doorLeafOffset :
        this.doorTopHeight + this.doorHeight - this.leaf2Left
    },
    inoxY1() {
      return this.doorTopHeight + this.doorHeight * .3
    },
    inoxY2() {
      return this.doorTopHeight + this.doorHeight * .5
    },
    inoxY3() {
      return this.doorTopHeight + this.doorHeight * .7
    },
    inoxWidth() {
      return this.showBg ?
        this.doorLeftWidth + this.doorWidth - this.lineX - this.doorLeafOffset :
        this.doorLeftWidth + this.doorWidth - this.lineX - this.leaf2Left
    },
  }
}
</script>
